<template>
  <!-- 查看图表 -->
  <div class="contain">
    <div>
      <el-page-header
        style="margin-bottom: 1.04167vw"
        @back="goBack"
        :content="'发病/患病率/生成图表'"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <div id="bar" class="echarts">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <div
              id="morChart"
              :style="{ width: '100%', height: '19.79167vw' }"
            ></div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <div
              id="fillChart"
              :style="{ width: '100%', height: '19.79167vw' }"
            ></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart: null,
      params: {
        type: "",
        orgId: "",
        parkId: "",
        classId: "",
        morbidityDate: "",
      },
    };
  },

  //页面初始化
  mounted() {
    let queryinfo = this.$route.query;
    console.log(queryinfo, "生成图表生成图表");
    this.params.type = queryinfo.type;
    this.params.orgId = queryinfo.orgId;
    this.params.parkId = queryinfo.parkId;
    this.params.classId = queryinfo.classId;
    this.params.morbidityDate = queryinfo.morbidityDate;

    // this.resizeHandler = debounce(() => {
    //   if (this.chart) {
    //     this.chart.resize();
    //   }
    // }, 100);
    // window.addEventListener("resize", this.resizeHandler);
    this.getChildLink();
  },

  methods: {
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    //图表数据
    getChildLink() {
      this.$api.getMorbidityInfoByMonthForHQChart(this.params).then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data, "chuxianchuchcjckckckcjkcjckjckjckck");
          let fillData = res.data.data.fill; //患病
          let morDate = res.data.data.mor; //发病

          this.myChartInit(morDate, fillData);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    myChartInit(morDate, fillData) {
      let morChart = this.$echarts.init(document.getElementById("morChart"));
      let fillChart = this.$echarts.init(document.getElementById("fillChart"));

      // 发病率
      var seriesData = [];
      for (var i = 0; i < morDate.rate.length; i++) {
        // console.log(i, "idezhizhizh");
        // console.log(morDate.rate.length);
        var itemStyle = {};

        if (i == morDate.rate.length - 1) {
          itemStyle = {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [4, 4, 0, 0],
              color: "#E7C69C",
            },
          };
        } else {
          itemStyle = {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [4, 4, 0, 0],
            },
          };
        }
        seriesData.push({
          value: morDate.rate[i],
          itemStyle: itemStyle,
        });
      }

      var morChartOption = {
        color: ["#004DA0"],
        title: {
          text: "发病率",
          left: 20,
          textStyle: {
            color: "#3D3E4F", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a} <br/>{b}: {c} %", // 这里是鼠标移上去的显示数据
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "28",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: morDate.name,
            axisLabel: {
              color: "#A8A6B9",
              interval: 0,
              rotate: 40,
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            // max: 40,
            axisLabel: {
              color: "#A8A6B9",
              formatter: "{value}%",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "发病率",
            type: "bar",
            barWidth: "40",
            data: seriesData,
          },
        ],
      };
      morChart.setOption(morChartOption);

      // 患病率
      var fillseriesData = [];
      for (var i = 0; i < fillData.rate.length; i++) {
        console.log(i, "idezhizhizh");
        console.log(fillData.rate.length);
        var itemStyle = {};

        if (i == fillData.rate.length - 1) {
          itemStyle = {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [4, 4, 0, 0],
              color: "#E7C69C",
            },
          };
        } else {
          itemStyle = {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [4, 4, 0, 0],
            },
          };
        }
        fillseriesData.push({
          value: fillData.rate[i],
          itemStyle: itemStyle,
        });
      }

      // 绘制出险数量趋势图图表
      var fillChartOption = {
        color: ["#004DA0"], //柱子颜色
        title: {
          text: "患病率",
          left: 20,
          textStyle: {
            color: "#3D3E4F", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a} <br/>{b}: {c} %", // 这里是鼠标移上去的显示数据
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "28",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: fillData.name,
            axisLabel: {
              color: "#A8A6B9",
              interval: 0,
              rotate: 40,
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#A8A6B9",
              formatter: "{value}%",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "患病率",
            type: "bar",
            barWidth: "40",
            data: fillseriesData,
          },
        ],
      };
      fillChart.setOption(fillChartOption);
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  padding: 34px 30px 30px 30px;
  background-color: #f4f4fc;

  /deep/.el-page-header__content {
    color: #3d3e4f;
    font-size: 22px;
    font-weight: bold;
  }

  .echarts {
    width: 100%;
    height: 100%;

    .el-row {
      // background-color: turquoise;
    }
    .el-col {
      border-radius: 4px;
      margin-bottom: 20px;
    }

    .bg-purple {
      background: #ffffff;
      padding: 30px 0 0 0;
    }

    .grid-content {
      border-radius: 4px;
      min-height: 80px;
    }
  }
}
</style>
